import companyService from 'services/CompanyService';
import s3FileService from 'services/S3FileService';
import { getFileExtension } from 'helpers/utils';
import { setGlobalError } from 'actions/GlobalError';
import logger from 'helpers/logger';
import { FETCH_COMPANY, UPDATE_COMPANY } from 'actions/Types';
import spinnerService from 'services/SpinnerService';
import { store } from '../../store';

// import companyHelpers from './CompanyHelpers';

export const updateCompany = (values, cb) => async (dispatch) => {
  try {
    const { data } = await companyService.updateCompany(values);

    dispatch({ type: UPDATE_COMPANY, payload: { id: values._id, data } });
    if (cb) cb();
  } catch (error) {
    dispatch(setGlobalError(error));
    if (cb) cb(error);
  }
};

export const fetchCompanyLogo = (filename, cb) => async (dispatch) => {
  try {
    spinnerService.startSpinner();
    const { url, type } = await s3FileService.geturl(filename);
    if (cb) cb(null, { url, type });
  } catch (error) {
    dispatch(setGlobalError(error));
    if (cb) cb(error);
  } finally {
    spinnerService.endSpinner();
  }
};

// eslint-disable-next-line no-unused-vars
export const changeCeoID = (file) => (dispatch) => {
  // TODO: UPLOAD FILE AT THE RIGHT PLACE AND DISPATCH
};

export const changeBankDetails = (bankDetails, cb) => (dispatch, getState) => {
  const { _id } = getState().loggedUserCompany.company;
  companyService
    .updateCompany({ _id, bank_details: bankDetails })
    .then((res) => {
      dispatch({ type: UPDATE_COMPANY, payload: { id: _id, data: res.data } });
      cb();
    })
    .catch(cb);
};

export const uploadCompanyFile = async (file, cb) => {
  const { _id, alternativeId } = store.getState().loggedUserCompany.company;

  const ext = getFileExtension(file.name);

  const folder = `company-files-${alternativeId}`;
  let filename = `${_id}-${new Date().toISOString()}.${ext}`;

  return s3FileService
    .upload(file, folder, filename)
    .then(() => {
      filename = `${folder}/${filename}`;
      return filename;
    })
    .catch(cb);
};

export const fetchCompany = () => async (dispatch, getState) => {
  try {
    const { _id } = getState().loggedUserCompany.company;
    spinnerService.startSpinner();
    const response = await companyService.fetchUserCompanies({ search: _id });
    if (response.data) {
      dispatch({
        type: FETCH_COMPANY,
        payload: response?.data?.docs[0],
      });
    }
    dispatch();
  } catch (error) {
    logger.error(error);
  } finally {
    spinnerService.endSpinner();
  }
};
