/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createProvider, fetchProviders } from 'actions/ProviderAction';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getTranslateSubCategory } from 'helpers/CategoryHelpers';
import { useDispatch, useSelector } from 'react-redux';
import snackbarService from 'services/SnackbarService';
import { getSubCategoryId } from 'helpers/providerHelpers';
import { SUB_CATEGORIES_PREFIX } from 'constants/expense';
import { Provider } from './useImportProviders';

const useImportAction = (
  { setProviderList, providerList },
  updatedProvider,
  openProviderFormDialog,
  checkValidateData
) => {
  const history = useHistory();
  const CONFIRM_TYPE = React.useState(null);
  const ROW_SELECTED = React.useState(null);
  const IS_CANCEL_CONFIRM = React.useState(true);
  const IS_SHOW_DIALOG = React.useState(false);
  const CONFIRM_MESSAGE = React.useState('');

  const [showSpinner, setShowSpinner] = React.useState(false);

  const [confirmType, setConfirmType] = CONFIRM_TYPE;
  const [rowSelected, setRowSelected] = ROW_SELECTED;
  const [isCancelConfirm, setIsCancelConfirm] = IS_CANCEL_CONFIRM;
  const [isShowDialog, setIsShowDialog] = IS_SHOW_DIALOG;
  const [confirmMessage, setConfirmMessage] = CONFIRM_MESSAGE;
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);

  //   const [providerSelected, setProviderSelected] = React.useState([]);

  const activities = useSelector((state) => state.activities);
  const activityIds = Object.keys(activities);
  const categories = useSelector((state) => state.categories);

  const navigate = (path) => {
    history.push(path);
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleUpdateProvider = (_updatedProvider) => {
    if (!providerList || !_updatedProvider) {
      return;
    }

    setProviderList((providerListClone) => {
      // get new acvitity name to assign id after add
      const oldProvider = providerListClone.find(
        (provider) => provider.index === _updatedProvider?.index
      );

      const activityDisplay = oldProvider?.activity;
      if (oldProvider && !activityIds.includes(activityDisplay)) {
        for (let i = 0; i < providerListClone.length; i++) {
          if (providerListClone[i].activity === activityDisplay)
            providerListClone[i].activity = _updatedProvider.activity;
        }
      }
      // eslint-disable-next-line no-param-reassign
      providerListClone[_updatedProvider.index] = _updatedProvider;

      // update all provider same new activity
      // for( let i = 0; i <providerListClone.length; i++ ) {
      //   if(providerListClone[index].activity===_updatedProvider)
      // }

      checkValidateData(providerListClone, false);
      return providerListClone;
    });
  };

  const getProviderSelected = React.useCallback(() => {
    const newProviderSelected = providerList.filter(
      ({ isSelected }) => isSelected
    );

    return newProviderSelected;
  }, [providerList]);

  React.useEffect(() => {
    handleUpdateProvider(updatedProvider);
  }, [updatedProvider]);

  const handleDeleteRow = (index) => {
    setConfirmType('deleteRow');
    setConfirmMessage(t('settings_section.providers.delete_provider'));
    setIsShowDialog(true);
    setRowSelected(index);
    setIsCancelConfirm(true);
  };

  const handleEditProvider = (index) => {
    if (providerList && providerList[index]) {
      openProviderFormDialog(providerList[index]);
    }
  };

  const handleSave = React.useCallback(() => {
    const newProviderSelected = getProviderSelected();
    if (!isEmpty(newProviderSelected)) {
      setConfirmType('save');
      setConfirmMessage(t('settings_section.providers.create_providers'));
      setIsShowDialog(true);
      setIsCancelConfirm(true);
    } else {
      setConfirmType('selectedEmpty');
      setConfirmMessage(
        `0 ${t('settings_section.providers.selected_providers')}`
      );
      setIsShowDialog(true);
      setIsCancelConfirm(false);
    }
  }, [providerList, getProviderSelected]);

  const handleToggleDialog = React.useCallback(
    (bool) => {
      setIsShowDialog(bool);
    },
    [setIsShowDialog]
  );

  const handleRemoveProvider = () => {
    const newProvidersTable = providerList.filter(
      ({ index }) => index !== rowSelected
    );
    setProviderList(newProvidersTable);
    setRowSelected(null);
    setConfirmType(null);
  };

  const getActivityId = (value) => {
    const values = Object.values(activities);
    const activity = values.find((item) => item.display === value);
    if (activity) {
      return activity._id;
    }
    return value;
  };

  const getActivity = (id) => {
    const activity = activities[id];
    if (activity) {
      return activity.display;
    }
    return id;
  };

  const getSubCategory = (provider) => {
    if (!categories.data) {
      return provider.id;
    }

    const category = Object.values(categories.data).find((item) => {
      if (!item.reference)
        return (
          item._id === provider.category ||
          provider.category === item.display ||
          provider.category === item.reference
        );

      return (
        item.referenceData._id === provider.category ||
        provider.category === item.referenceData.display
      );
    });
    if (category) {
      if (!category.reference) {
        const subCategory = category.children?.find((item) => {
          if (item.reference)
            return item.referenceData._id === provider.subCategory;
          return item._id === provider.subCategory;
        });

        if (subCategory) {
          return getTranslateSubCategory(subCategory, t);
        }
      } else {
        const children = Object.values(categories.data).filter(
          (item) => item.parent === category.referenceData._id
        );
        const subCategory = children?.find((item) => {
          return item._id === provider.subCategory;
        });

        if (subCategory) {
          return t(`${SUB_CATEGORIES_PREFIX}${subCategory.display}`);
        }
      }
    }

    return provider.subCategory;
  };

  const handleSubmit = React.useCallback(() => {
    const data = getProviderSelected().map((elem) =>
      Provider.toJson(elem, getActivityId)
    );

    setShowSpinner(true);
    dispatch(
      createProvider(data, (err, res) => {
        setShowSpinner(false);
        setIsOpenSnackBar(true);
        if (err) {
          if (err.message === 'All providers are existed')
            snackbarService.startSnackbar({
              message: t('settings_section.providers.duplicate'),
              snackBarType: 'error',
              autoHideDuration: 6000,
            });
          else {
            snackbarService.startSnackbar({
              message: t('settings_section.providers.create_failed'),
              snackBarType: 'error',
              autoHideDuration: 6000,
            });
          }
        } else {
          const successMessage = [
            res.length,
            t('settings_section.providers.create_successfully'),
          ].join(' ');

          snackbarService.startSnackbar({
            message: successMessage,
            snackBarType: 'success',
            autoHideDuration: 6000,
          });
          dispatch(fetchProviders());
          navigate('/settings/providers');
        }
      })
    );
  }, [getProviderSelected]);

  const handleOnConfirm = (isConfirm, type) => {
    if (isConfirm) {
      switch (type) {
        case 'deleteRow':
          handleRemoveProvider();
          break;
        case 'save':
          handleSubmit();
          break;
        case 'importError':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'importEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'selectedEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        default:
          break;
      }
    }
  };

  const handleImportProviderTableData = (providers) => {
    const data = providers.map((provider) => {
      const newProvider = { ...provider };
      newProvider.activity = getActivity(provider.activity);
      newProvider.subCategory = getSubCategoryId(provider, categories, t);
      return newProvider;
    });
    return data;
  };

  return {
    handleDeleteRow,
    handleEditProvider,
    handleSave,
    handleToggleDialog,
    handleRemoveProvider,
    handleSubmit,
    handleOnConfirm,
    getActivity,
    getSubCategory,
    handleImportProviderTableData,
    getActivityId,
    // updateProviderSelected,
    getProviderSelected,
    state: {
      confirmType,
      rowSelected,
      isCancelConfirm,
      isShowDialog,
      confirmMessage,
      showSpinner,
      isOpenSnackBar,
    },
  };
};

export default useImportAction;
